import useSWR from "swr";
import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useSession, signOut } from "next-auth/react";
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
} from "@novu/notification-center";

const TopNav = ({ showMobmenu }) => {
  const [switchCompany, setSwitchCompany] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { data: session, update } = useSession();
  const { locales, push, pathname, asPath } = useRouter();
  const { t } = useTranslation("common");

  const updateSession = async (company) => {
    company.current = true;

    await update({
      ...session,
      user: {
        ...session.user,
        currentCompany: company,
      },
    });
  };

  async function fetcher(...urls) {
    const f = (u) =>
      fetch(u).then((r) => {
        if (!r.ok) {
          const error = new Error("An error occurred while fetching the data.");
          error.info = r.json();
          error.status = r.status;
          throw error;
        }
        return r.json();
      });

    if (urls.length > 1) {
      return Promise.all(urls.map(f));
    }
    return f(urls);
  }

  const { data, error } = useSWR(["/api/v1/companies/my-companies"], fetcher, {
    onErrorRetry: (error) => {
      if (error.status === 404) return;
    },
  });

  React.useEffect(() => {
    function updateMargin() {
      const rightElement = document.getElementById("top-nav");
      const leftElement = document.getElementById("sidenav-main");

      // check if the aside element is visible in the viewport
      const leftRect = leftElement?.getBoundingClientRect();

      if (leftRect && leftRect.right > 0 && leftRect.left < window.innerWidth) {
        const leftWidth = leftElement.clientWidth;
        rightElement.style.marginLeft = leftWidth + "px";
      } else {
        rightElement && (rightElement.style.marginLeft = "0px");
      }
    }

    updateMargin();
    window.addEventListener("resize", updateMargin);
    window.addEventListener("scroll", updateMargin);
  }, []);

  const toggleSideBar = async () => {
    if (open) {
      document.getElementById("main-content").style.marginLeft = "250px";
      document.getElementById("sidenav-main").style.display = "block";
      document.getElementById("top-nav").style.marginLeft = "250px";
      setOpen(false);
    } else {
      document.getElementById("main-content").style.marginLeft = "0px";
      document.getElementById("sidenav-main").style.display = "none";
      document.getElementById("top-nav").style.marginLeft = "0px";
      setOpen(true);
    }
  };

  return (
    <nav
      className="navbar navbar-top navbar navbar-main navbar-expand-lg sticky-top px-0 opacity-9 shadow-lg bg-white"
      data-scroll="true"
      style={{ zIndex: 99, marginLeft: "0px !important" }}
      id="top-nav"
    >
      <div className="container-fluid my-2">
        <div className="d-flex align-items-center ms-3">
          <div className="sidenav-toggler sidenav-toggler-inner cursor-pointer d-block d-xl-none">
            <a onClick={showMobmenu} className="nav-link text-body p-0">
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line fa-2x" />
                <i className="sidenav-toggler-line fa-2x" />
                <i className="sidenav-toggler-line fa-2x" />
              </div>
            </a>
          </div>
          <div className="sidenav-toggler sidenav-toggler-inner cursor-pointer d-none d-xl-block">
            <a
              onClick={() => toggleSideBar()}
              className="nav-link text-body p-0"
            >
              <div className="sidenav-toggler-inner">
                <i
                  className="sidenav-toggler-line fa-2x"
                  style={
                    !open ? { transform: "translateX(5px)", width: "13px" } : {}
                  }
                />
                <i className="sidenav-toggler-line fa-2x" />
                <i
                  className="sidenav-toggler-line fa-2x"
                  style={
                    !open ? { transform: "translateX(5px)", width: "13px" } : {}
                  }
                />
              </div>
            </a>
          </div>

          <div className="d-block d-md-none ms-5">
            <p className="text-dark">{t("common:app-name")}</p>
          </div>
        </div>

        <ul className="nav">
          <li className="dropdown me-3">
            <i
              className=" fas fa-language fa-2x text-bota-strong-cyan-lime-green"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            />
            <ul className="dropdown-menu dropdown-menu-end">
              {locales.map((locale) => (
                <li
                  key={locale}
                  onClick={() => {
                    push(pathname, asPath, { locale });
                    localStorage.setItem("BOTATENDE_LOCALE", locale);
                  }}
                >
                  <a className="dropdown-item" href="#">
                    <span>
                      <i className="fas fa-globe" />
                      <span className="ms-3">
                        {locale === "en"
                          ? "English"
                          : locale === "pt"
                          ? "Português"
                          : locale}
                      </span>
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </li>

          <li className="mx-2">
            <NovuProvider
              backendUrl={process.env.NEXT_PUBLIC_NOVU_BACKEND_URL}
              socketUrl={process.env.NEXT_PUBLIC_NOVU_SOCKET_URL}
              applicationIdentifier={process.env.NEXT_PUBLIC_NOVU_APP_ID}
              subscriberId={session?.user?.subscriberId}
              subscriberHash={session?.user?.subscriberHash}
              styles={{
                footer: {
                  root: { display: "none" },
                },
              }}
            >
              <PopoverNotificationCenter colorScheme={"light"}>
                {({ unseenCount }) => (
                  <NotificationBell unseenCount={unseenCount} />
                )}
              </PopoverNotificationCenter>
            </NovuProvider>
          </li>

          <li className="dropdown">
            <span
              className="avatar avatar-sm rounded-circle bg-bota-strong-cyan-lime-green"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-user fa-2x text-bota-light-grayish-orange" />
            </span>
            <ul className="dropdown-menu menu-2 dropdown-menu-end">
              {switchCompany && (
                <>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSwitchCompany(false);
                      }}
                    >
                      <i className="fas fa-arrow-left" />
                      <span className="ms-3">{t("common:top-nav.back")}</span>
                    </button>

                    {/* create company */}
                    {data?.companies?.length === 0 &&
                      !session.user?.currentCompany && (
                        <Link
                          href="/companies"
                          className="dropdown-item"
                          passHref
                        >
                          <i className="fas fa-building" />
                          <span className="ms-3">
                            {t("common:top-nav.create-a-company")}
                          </span>
                        </Link>
                      )}
                  </li>

                  {session.user?.currentCompany && (
                    <li
                      className="dropdown-item"
                      style={{
                        backgroundColor: "var(--bs-gray-400)",
                      }}
                    >
                      <button className="dropdown-item">
                        <span
                          className={`avatar avatar-sm rounded-circle bg-${
                            [
                              "bota-dark-cyan-lime-green",
                              "bota-strong-cyan-lime-green",
                              "bota-light-blue",
                              "dark",
                              "primary",
                              "info",
                              "warning",
                            ][
                              Math.floor(
                                Math.random() *
                                  [
                                    "bota-dark-cyan-lime-green",
                                    "bota-strong-cyan-lime-green",
                                    "bota-light-blue",
                                    "dark",
                                    "primary",
                                    "info",
                                    "warning",
                                  ].length
                              )
                            ]
                          }`}
                        >
                          <span className="avatar-content">
                            {session.user?.currentCompany?.name
                              .charAt(0)
                              .toUpperCase() +
                              session.user?.currentCompany?.name
                                .split(" ")
                                .slice(-1)
                                .pop()
                                .charAt(0)
                                .toUpperCase()}
                          </span>
                        </span>

                        <span className="ms-3">
                          {session.user?.currentCompany?.name}
                        </span>
                        {session.user?.currentCompany?.current && (
                          <i className="ms-2 text-dark fas fa-check" />
                        )}
                      </button>
                    </li>
                  )}

                  {!error &&
                    data?.companies?.map(
                      (company) =>
                        // if the company is not in the session.user.currentCompany, show it
                        !session.user?.currentCompany ||
                        (session.user?.currentCompany?.id !== company?.id && (
                          <li key={company?.id} className="dropdown-item">
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                updateSession(company);
                                setSwitchCompany(false);
                              }}
                            >
                              <span
                                className={`avatar avatar-sm rounded-circle bg-${
                                  [
                                    "bota-dark-cyan-lime-green",
                                    "bota-strong-cyan-lime-green",
                                    "bota-light-blue",
                                    "dark",
                                    "primary",
                                    "info",
                                    "warning",
                                  ][
                                    Math.floor(
                                      Math.random() *
                                        [
                                          "bota-dark-cyan-lime-green",
                                          "bota-strong-cyan-lime-green",
                                          "bota-light-blue",
                                          "dark",
                                          "primary",
                                          "info",
                                          "warning",
                                        ].length
                                    )
                                  ]
                                }`}
                              >
                                <span className="avatar-content">
                                  {company?.name.charAt(0).toUpperCase() +
                                    company?.name
                                      .split(" ")
                                      .slice(-1)
                                      .pop()
                                      .charAt(0)
                                      .toUpperCase()}
                                </span>
                              </span>

                              <span className="ms-3">{company?.name}</span>
                            </button>
                          </li>
                        ))
                    )}

                  {/* if no session.user.companies, show a create company message with link to /companies */}
                  {session.user?.companies?.length === 0 && (
                    <li>
                      <Link
                        href="/companies"
                        className="dropdown-item"
                        passHref
                      >
                        <i className="fas fa-building" />
                        <span className="ms-3">
                          {t("common:top-nav.create-a-company")}
                        </span>
                      </Link>
                    </li>
                  )}
                </>
              )}

              {!switchCompany && (
                <>
                  {/* profile */}
                  <li>
                    <Link href="/profile" className="dropdown-item" passHref>
                      <i className="fas fa-user" />
                      <span className="ms-3">
                        {t("common:top-nav.my-profile")}
                      </span>
                    </Link>
                  </li>
                  {/* billing */}
                  <li>
                    <Link href="/billing" className="dropdown-item" passHref>
                      <i className="fas fa-file-invoice-dollar" />
                      <span className="ms-3">{t("common:menu.billing")}</span>
                    </Link>
                  </li>
                  <hr className="dropdown-divider" />
                  {session && (
                    <>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSwitchCompany(true);
                          }}
                        >
                          <i className="fas fa-building" />
                          <span className="ms-3">
                            {t("common:top-nav.switch-company")}
                          </span>
                        </button>
                      </li>

                      <hr className="dropdown-divider" />

                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => signOut({ redirect: false })}
                        >
                          <i className="fas fa-person-running" />
                          <span className="ms-3">
                            {t("common:top-nav.logout")}
                          </span>
                        </a>
                      </li>
                    </>
                  )}
                </>
              )}
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default TopNav;
