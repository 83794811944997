import NProgress from "nprogress";
import * as Sentry from "@sentry/react";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import ErrorBoundary from "../src/ErrorBoundary";
import { appWithTranslation } from "next-i18next";
import { SettingsProvider } from "@hooks/settings";
import AuthLayout from "../src/layouts/AuthLayout";
import GuestLayout from "../src/layouts/GuestLayout";
import { GoogleTagManager } from "@next/third-parties/google";
import { SessionProvider, useSession } from "next-auth/react";

import "../styles/nprogress.css";
import "../styles/scss/dentricedev.scss";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// for reporting web vitals whenever needed
// export function reportWebVitals(metric) {
//   console.log(metric);
// }

function MyApp({ Component, pageProps }) {
  const [open, setOpen] = React.useState(false);
  const router = useRouter();
  const showMobilemenu = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const bootstrap = require("bootstrap");
    window.bootstrap = bootstrap;
    if (open) {
      document.body.classList.add("g-sidenav-pinned");
    } else {
      document.body.classList.remove("g-sidenav-pinned");
    }

    if (document.body.classList.contains("g-sidenav-pinned")) {
      // attach an event listener to document, when clicked remove g-sidenav-pinned class
      document.body.addEventListener("click", function (event) {
        if (
          !event.target.closest(".sidenav-toggler") &&
          !event.target.closest(".sidenav-toggler-inner") &&
          !event.target.closest("#sidenav-main")
        ) {
          setOpen(!open);
          document.body.classList.remove("g-sidenav-pinned");
        }
      });

      // attach an event listener to document when keydown ESC remove g-sidenav-pinned class
      document.addEventListener("keydown", function (event) {
        if (event.key === "Escape") {
          setOpen(!open);
          document.body.classList.remove("g-sidenav-pinned");
        }
      });
    }

    router.events.on("routeChangeStart", () => NProgress.start());

    router.events.on("routeChangeComplete", () => NProgress.done());
    router.events.on("routeChangeError", () => NProgress.done());
  }, [open, router.events]);

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundary} showDialog>
      <SessionProvider session={pageProps.session}>
        <SettingsProvider>
          {Component.auth ? (
            <Auth>
              <AuthLayout showMobilemenu={() => showMobilemenu()}>
                <Component {...pageProps} />
              </AuthLayout>
            </Auth>
          ) : (
            <GuestLayout>
              <Component {...pageProps} />
            </GuestLayout>
          )}

          {/* {process.env.NODE_ENV === "production" && (
            <GoogleTagManager gtmId="GTM-MLCK68TF" />
          )} */}
        </SettingsProvider>
      </SessionProvider>
    </Sentry.ErrorBoundary>
  );
}

function Auth({ children }) {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status } = useSession({ required: true });

  if (status === "loading") {
    return (
      <div className="row m-auto">
        <div className="col-12 bg-bota-strong-cyan-lime-green min-vh-100">
          <div className="row justify-content-center">
            <div id="preloader1"></div>
          </div>
        </div>
      </div>
    );
  }

  return children;
}

export default appWithTranslation(MyApp);
